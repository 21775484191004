<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-menu-setting-modal" title="Menu Setting Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="menu_setting_desc" class="col-form-label col-form-label-sm">Menu Setting Desc <span class="custom-required">*</span></label>
              <select v-model="menu_setting.menu_setting_desc" v-validate="{ required: true }" id="menu_setting_desc" name="menu_setting_desc" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a menu setting desc</option>
                <option value="PRODUCT_REQUISITION_CREATE">PRODUCT_REQUISITION_CREATE</option>
                <option value="DELIVERY_CHALLAN_CREATE">DELIVERY_CHALLAN_CREATE</option>
              </select>
              <div class="invalid-feedback">Menu setting desc is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="status" class="col-form-label col-form-label-sm">Status  <span class="custom-required">*</span></label>
              <select v-model="menu_setting.status" v-validate="{ required: true }" id="status" name="status" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a status</option>
                <option value="1">ON</option>
                <option value="0">OFF</option>
              </select>
              <div class="invalid-feedback">Status is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_id" class="col-form-label col-form-label-sm">Hub  <span class="custom-required">*</span></label>
              <select v-model="menu_setting.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub name is required</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-menu-setting-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'addUpdateMenuSetting',
  props: ['menu_setting', 'btnEdit'],
  data() {
    return {
      validation_errors: {},
      hubs: [],
      show: false,
      loader: false,
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/menu-settings', this.menu_setting).then(response => {
            this.loader = false
            this.$notification.success({
              message: response.data.message,
            })
            $('#menu_setting_list').DataTable().destroy()
            this.$emit('resetForm')
            this.$emit('getMenuSettings')
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/menu-settings/' + this.menu_setting.id, this.menu_setting)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#menu_setting_list').DataTable().destroy()
                this.$bvModal.hide('add-update-menu-setting-modal')
                this.$emit('getMenuSettings')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
